const commaSeparatedNameRegex = /^([^,]+),([^,]+)$/;

/**
 * Naive attempt to format name as "lastnames, firstname" -> "firstname lastnames"
 */
export const formatNameInformal = (name: string): string =>
  name?.match(commaSeparatedNameRegex) ? name.replace(commaSeparatedNameRegex, "$2 $1").trim() : name;

export const normalizeIsbn = (isbn: string): string => isbn.replace(/[^0-9x-]/gi, "");

const hasToString = (item: unknown): item is { toString: () => string } =>
  typeof item === "object" && typeof item?.["toString"] === "function";

export const getErrorMessage = (error: unknown): string => {
  if (!error) return "";
  if (error instanceof Error) return error.message;
  if (hasToString(error)) return error.toString();
  return JSON.stringify(error);
};
