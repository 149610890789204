import { DecorativeImage, ImageWithMetadata, Library } from "@libry-content/types";
import { useCommonData } from "../layout/CommonDataProvider";
import type { Library as SchemaOrgLibrary } from "schema-dts";
import { getSiteUrl, imageUrlBuilder } from "@libry-content/common";
import { OpeningHoursHelper } from "../openingHours/openingHoursHelper/openingHoursHelper";
import { TranslateSanityContent } from "../../utils/hooks/useTranslation";
import { getPath } from "../../utils/getPath";
import { ResolvedSite } from "../../utils/fetchWithCommonDataAndCurrentSiteFilter";

export const sortLibraries = (site: ResolvedSite, libraries: Library[] = []) => {
  const getSortIndex = ({ _id }: Library) => site.libraryOrdering?.findIndex(({ _ref }) => _ref === _id) ?? 0;

  return [...libraries].sort((libraryA, libraryB) => getSortIndex(libraryA) - getSortIndex(libraryB));
};

export const useSortedLibraries = (libraries: Library[] = []) => {
  const { site } = useCommonData();
  if (!site) {
    console.error("Could not find site when sorting libraries");
    return libraries;
  }

  return sortLibraries(site, libraries);
};

export const getSchemaOrgImageUrl = (image: ImageWithMetadata | DecorativeImage) =>
  imageUrlBuilder(image) // https://sanity-io-land.slack.com/archives/C9Z7RC3V1/p1638963510039100
    ?.ignoreImageParams()
    .size(100, 100)
    .fit("fillmax")
    .bg("0000")
    .format("png")
    .url() ?? "";

export const getSchemaOrgLibrary = (
  ts: TranslateSanityContent,
  library: Pick<
    Library,
    "_id" | "_type" | "name" | "openingHours" | "selfServiceOpeningHours" | "contactInfo" | "image"
  >,
  site: ResolvedSite
): Exclude<SchemaOrgLibrary, string> => {
  const openingHoursHelper = new OpeningHoursHelper(site, library);
  const visitingAddress = library.contactInfo?.visitingAddress;

  return {
    "@type": "Library",
    name: ts(library.name),
    url: `${getSiteUrl(site)}${getPath(library)}`,
    openingHoursSpecification: openingHoursHelper.schemaOrgOpeningHours,
    specialOpeningHoursSpecification: openingHoursHelper.schemaOrgSpecialOpeningHours,
    telephone: library.contactInfo?.phone,
    email: library.contactInfo?.email,
    address: visitingAddress && {
      "@type": "PostalAddress",
      streetAddress: visitingAddress?.streetAddress,
      postalCode: visitingAddress?.postalCode,
      addressLocality: visitingAddress?.city,
    },
    ...(library.image?.asset ? { image: getSchemaOrgImageUrl(library.image) } : {}),
  };
};
