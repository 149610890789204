export const isJestTest = process.env.NODE_ENV === "test";

function requireEnv(key: string, value: string | undefined): string {
  if (isJestTest) return value ?? ""; // Fjerner støy i logger
  if (!value) {
    console.error(`🚦🛑 Environment variable ${key} is empty or undefined`);
    return "";
  }
  return value;
}

const platformDomain = requireEnv("SANITY_STUDIO_PLATFORM_DOMAIN", process.env.SANITY_STUDIO_PLATFORM_DOMAIN);

// Note: For the NextJS compiler to find environment variables, they cannot be accessed
// using object destructuring or any other way than process.env.NAME.
export const platform = {
  /**
   * Host: The host part of the base platform URL, consists of the domain name and optionally a port number.
   * Examples: "bibliotek.io", "pr133.qa.bibliotek-test.io", "localhost:3000"
   */
  host: platformDomain,

  /**
   * Hostname: Only the domain name part of the base platform URL, not including port numbers.
   * Examples: "bibliotek.io", "pr133.qa.bibliotek-test.io", "localhost"
   */
  hostname: platformDomain.split(":")[0],
};

export const getPlatformUrl = (subdomain?: string | undefined): string =>
  subdomain ? `https://${subdomain}.${platform.host}` : `https://${platform.host}`;

const localTopDomain = ".local";
export const localHostname = `bibliotek${localTopDomain}`;
export const localPort = 3000;
export const localHost = `${localHostname}:${localPort}`;
export const isLocal = (hostname: string) => hostname.match(localTopDomain);

export const PRODUCTION_DATASET = "production";

export const SANDBOX_DATASET = "test";
