import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import differenceInCalendarMonths from "date-fns/differenceInCalendarMonths";
import { UTCDateToNorwegian } from "./date";

// We hardcode these so we can use them to decide whether to fetch sommerles assets on front page
export const sommerlesStartDate = "06-01";
export const sommerlesEndDate = "08-31";

export const isSommerlesSeason = ({ monthsBefore } = { monthsBefore: 0 }): boolean => {
  const now = new Date();

  const thisYear = now.getFullYear();
  const startDate = UTCDateToNorwegian(new Date(`${thisYear}-${sommerlesStartDate}`));
  const endDate = UTCDateToNorwegian(new Date(`${thisYear}-${sommerlesEndDate}`));

  const monthsUntilStart = differenceInCalendarMonths(startDate, now);
  const daysSinceEnd = differenceInCalendarDays(now, endDate);

  return monthsUntilStart <= monthsBefore && daysSinceEnd < 1;
};
