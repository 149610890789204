import { Site } from "@libry-content/types";
import { SanityClient } from "@sanity/client";
import { PRODUCTION_DATASET } from "./platform";

export function getSiteContentResourceName(site: Pick<Site, "_id">) {
  return `content-${site._id}`;
}

export function getSiteEditorRoleName(client: SanityClient, site: Pick<Site, "_id">) {
  const dataset = client.config().dataset;
  const namespace = dataset === PRODUCTION_DATASET ? "" : `${dataset}-`;
  return `${namespace}editor-${site._id}`;
}
