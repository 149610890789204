import { isProductionDataset } from "./../../../apps/frontend/src/utils/currentDataset";
import { DecorativeImage, Modify, Publication } from "@libry-content/types";
import { getPlatformUrl } from "./platform";
import { screenshotApiUrl } from "./screenshotService";

const getBookListImages = (publications: Publication[]) =>
  (
    publications
      ?.map((publication) => publication?.image)
      ?.filter((image): image is DecorativeImage => image !== undefined) || []
  ).slice(0, 3);

export type PublicationWithImage = Modify<Publication, { image: DecorativeImage }>;

export const isPublicationWithImage = (publication?: Publication): publication is PublicationWithImage =>
  !!publication?.image?.asset;

export const getPublicationsWithImage = (publications: Publication[] = []): PublicationWithImage[] =>
  (publications?.filter(isPublicationWithImage) || []).slice(0, 3);

const getBookListFanUrl = (publications: Publication[]) => {
  const images = getBookListImages(publications)
    .map((image) => image.asset._ref)
    .join(",");
  return images.length ? getPlatformUrl() + `/felles/bokvifte?bilder=${images}` : undefined;
};

export const getBookListScreenshotUrl = (publications: Publication[], isDraft: boolean = false) => {
  const targetUrl = getBookListFanUrl(publications);
  const area = isProductionDataset ? "0,70,600,314" : "0,100,600,314"; // pga. Testenvironmentbanner
  return targetUrl
    ? `${screenshotApiUrl}?url=${encodeURIComponent(targetUrl)}&area=${area}${isDraft ? "&draft=true" : ""}`
    : undefined;
};
