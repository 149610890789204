import { ClientConfig } from "@sanity/client";

const dataset = process.env.SANITY_STUDIO_API_DATASET;

// Make 'projectId' and 'dataset' required, as needed by the frontend client
type SanityClientConfig = ClientConfig & {
  projectId: string;
  dataset: string;
};

export const sanityConfig: SanityClientConfig = {
  dataset: dataset || "no-dataset-configured",
  projectId: "v0tvkqmw",
  apiVersion: "2021-12-16",
};
